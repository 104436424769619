<template>
  <base-form-layout
    :title="`${action} ${$tc(`user.${category}`, 1)}`"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <user-group-edit-form
        :user-group="userGroup"
        :category="category"
      /> </v-form
  ></base-form-layout>
</template>

<script>
import UserGroupEditForm from './components/UserGroupEditForm'
import { USER_GROUP } from '@/models/user.group'
import { DocumentMixin } from '@/mixins/document.mixin.js'
import { mapActions } from 'vuex'
export default {
  components: {
    UserGroupEditForm
  },
  mixins: [DocumentMixin],
  props: {
    groupId: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      beforeSubmit: true,
      userGroup: this.createUserGroupObject()
    }
  },
  computed: {
    action() {
      return this.$t(this.groupId ? 'actions.edit' : 'actions.create')
    }
  },
  async created() {
    if (this.groupId) {
      this.userGroup = await this.fetchUserGroup(this.groupId)
    }
  },
  methods: {
    ...mapActions({
      addNotification: 'notification/add',
      fetchUserGroup: 'userGroup/fetch'
    }),

    createUserGroupObject() {
      const userGroup = this._.cloneDeep(USER_GROUP(this.category))
      if (this.category === 'customer') {
        delete userGroup.attributes.customAttributes.certificates
        delete userGroup.attributes.customAttributes.speciesInfo
      }
      return userGroup
    },

    async onSubmit() {
      this.beforeSubmit = false
      try {
        const action = this.groupId ? 'update' : 'create'
        const userGroup = await this.$store.dispatch(
          `userGroup/${action}`,
          this.userGroup
        )
        this.createDocument(userGroup.id, 'UserGroup', 'Attachment')
        this.$refs.form.reset()
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `Successfully ${action}d` }
        })
        if (this.$route.query.redirect === 'back') this.$router.go(-1)
        else this.routeToUserGroups(userGroup)
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    },
    routeToUserGroups(userGroup) {
      const groupId = userGroup.id
      const category = this.category
      this.$router.push({
        name: 'user-groups',
        params: { groupId, category }
      })
    }
  }
}
</script>
