<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="6">
        <BaseFieldInput
          v-model="userGroup.attributes.name"
          :label="$t('userGroup.companyName')"
        />
      </v-col>

      <v-col cols="12" md="6">
        <BaseSelector
          v-if="userGroup.id !== currentUserGroup.id"
          v-model="userGroup.attributes.category"
          :hint="'*' + $t('common.mandatoryFields')"
          persistent-hint
          :label="`${$tc('userGroup.company', 1)} (*)`"
          :items="companyCategories"
          :item-text="user => $tc(`user.${user.value}`, 1)"
          :disabled="!userGroup.id"
        />
      </v-col>

      <v-col cols="12" md="6">
        <BaseFieldInput
          v-model="userGroup.attributes.customAttributes.taxId"
          :label="$t('userGroup.taxId')"
      /></v-col>
      <v-col v-if="!isCustomer" cols="12" md="6">
        <BaseFieldInput
          v-model="userGroup.attributes.customAttributes.licenseCode"
          :label="$t('userGroup.licenseCode')"
          placeholder="C112233"
      /></v-col>

      <v-col cols="12" md="6">
        <UserGroupSelector
          v-if="isSupplier"
          v-model="userGroup.attributes.customAttributes.forestManagers"
          multiple
          return-object
          :label="`${$t('selectors.select')} ${$tc('user.forestManager', 2)}`"
          category="forest_manager"
        />
      </v-col>
    </v-row>

    <h5 class="mb-5 text-h6">{{ $t('common.address') }}</h5>
    <v-row dense>
      <v-col cols="12" md="6">
        <BaseFieldInput
          v-model="userGroup.attributes.customAttributes.address.street"
          :label="$t('common.street')"
        />
      </v-col>
      <v-col>
        <BaseFieldInput
          v-model="userGroup.attributes.customAttributes.address.country"
          :label="$t('common.country')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <BaseFieldInput
          v-model="userGroup.attributes.customAttributes.address.state"
          :label="$t('common.state')"
        />
      </v-col>

      <v-col>
        <BaseFieldInput
          v-model="userGroup.attributes.customAttributes.address.city"
          :label="$t('common.city')"
        />
      </v-col>
      <v-col>
        <BaseFieldInput
          v-model="userGroup.attributes.customAttributes.address.zipcode"
          :label="$t('common.zipcode')"
      /></v-col>
    </v-row>

    <!-- <v-row v-if="!isCustomer">
      <v-col cols="12">
        <v-divider class="my-5"></v-divider>
        <species-selector :user-group="userGroup"
      /></v-col>
      <v-col cols="12">
        <v-divider class="my-5"></v-divider>
        <CertificatesSelector :user-group="userGroup"
      /></v-col>
    </v-row> -->

    <h6 class="text-h6 my-5">{{ $tc('document.attachment', 2) }}</h6>
    <v-divider class="mb-8"></v-divider>
    <base-file-input />
  </div>
</template>

<script>
import UserGroupSelector from './UserGroupSelector'
// import AlgoliaPlaces from '@/views/user/components/AlgoliaPlaces'
// import SpeciesSelector from './SpeciesSelector'
// import CertificatesSelector from './CertificatesSelector'
import { COMPANY_CATEGORIES } from '@/common/terms.js'
import { mapGetters } from 'vuex'
export default {
  components: {
    UserGroupSelector
    // AlgoliaPlaces,
    // SpeciesSelector,
    // CertificatesSelector
  },
  props: {
    userGroup: {
      type: Object,
      required: true
    },
    category: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      companyCategories: COMPANY_CATEGORIES
    }
  },
  computed: {
    isSupplier() {
      return this.category === 'supplier'
    },
    isCustomer() {
      return this.category === 'customer'
    },
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      currentUserGroup: 'auth/currentUserGroup'
    })
  }
}
</script>
